<template>
	<div :class="{ contain: true, mobile: isMobile }">
		<div class="d-flex flex-column">
				<div class="content d-flex flex-column flex-lg-row">
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openOfficial">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/Uptick_c7.jpg" alt="" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								Uptick Mainnet Airdrop Countdown｜Uptick Official
							</div>
						
						</div>
					</div>
				</div>
			
							
			</div>

		
		</div>
	</div>
</template>

<script>
	import api from "@/api"
	import { getSettingArr } from "@/utils/helper";
	export default {

		data() {
			return {
				dataLIst: [{
					src: "",
					id: 1,
					title: "",
					time: "",
				}, ],
				isConstellationEnd: false,
				isPhotographyEnd: false,
				isConstellationV2End:false,
				d1: "",
				d2: "",
				h1: "",
				h2: "",
				m1: "",
				m2: "",
				s1: "",
				s2: "",
				d3: "",
				d4: "",
				h3: "",
				h4: "",
				m3: "",
				m4: "",
				s3: "",
				s4: "",
				d5: "",
				d6: "",
				h5: "",
				h6: "",
				m5: "",
				m6: "",
				s5: "",
				s6: "",
				secondsPhotography: "",
				now: "",
				endPhotography: "",
				endConstellation: "",
				endConstellationV2: "",
				secondsConstellation: "",
			};
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
		},
		async mounted() {
			this.showNoRead();
			this.Time();
			var timestamp = Date.parse(new Date()).toString();
			let nowTimeStamp = timestamp.substr(0, 10);
			// 星座结束时间
			let constellationEndTimeStamp = 1673366399;
			// 摄像作品结束时间
			let photographyEndTimeStamp = 1669608000;
			//星座第二期结束时间
			let isConstellationV2EndTimeStamp = 1678946400;
			if (nowTimeStamp > isConstellationV2EndTimeStamp) {
				this.isConstellationV2End = true;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("结束");
			} else {
				this.isConstellationV2End = false;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("开始");
			}

			if (nowTimeStamp > photographyEndTimeStamp) {
				this.isPhotographyEnd = true;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("结束");
			} else {
				this.isPhotographyEnd = false;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("开始");
			}

			if (nowTimeStamp > constellationEndTimeStamp) {
				this.isConstellationEnd = true;
				console.log(nowTimeStamp);
				console.log(constellationEndTimeStamp);
				console.log("结束");
			} else {
				this.isConstellationEnd = false;
				console.log(nowTimeStamp);
				console.log(constellationEndTimeStamp);
				console.log("开始");
			}
		},
		methods: {
			getTime() {
				// 摄像作品结束时间
				let photographyEndTimeStamp = 1673366399000;
				let constellationEndTimeStamp = 1669608000000;
				let isConstellationV2EndTimeStamp = 1678946400000;
				this.now = new Date().valueOf();
				this.endPhotography = photographyEndTimeStamp;
				this.endConstellation = constellationEndTimeStamp;
				this.endConstellationV2 = isConstellationV2EndTimeStamp;
				//摄影
				if (this.now < this.endPhotography) {
					this.secondsPhotography = (this.endPhotography - this.now) / 1000;
				}
				//摄影
				if (this.now < this.endConstellationV2) {
					this.secondsConstellationV2 = (this.endConstellationV2 - this.now) / 1000;
				}
				//竞赛
				if (this.now < this.endConstellation) {
					this.secondsConstellation = (this.endConstellation - this.now) / 1000;
				}
			},
			// 天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.secondsPhotography / (24 * 60 * 60));
				let day = d < 10 ? "0" + d : d;
				this.d1 = String(day).split("")[0];
				this.d2 = String(day).split("")[1];
				//let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
				let h = parseInt((this.secondsPhotography / (60 * 60)) % 24);
				let hour = h < 10 ? "0" + h : h;
				this.h1 = String(hour).split("")[0];
				this.h2 = String(hour).split("")[1];
				let m = parseInt((this.secondsPhotography / 60) % 60);
				let minutes = m < 10 ? "0" + m : m;
				this.m1 = String(minutes).split("")[0];
				this.m2 = String(minutes).split("")[1];
				let s = parseInt(this.secondsPhotography % 60);
				let second = s < 10 ? "0" + s : s;
				this.s1 = String(second).split("")[0];
				this.s2 = String(second).split("")[1];
			},
			countDown1() {
				let d = parseInt(this.secondsConstellation / (24 * 60 * 60));
				let day = d < 10 ? "0" + d : d;
				this.d3 = String(day).split("")[0];
				this.d4 = String(day).split("")[1];
				//let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
				let h = parseInt((this.secondsConstellation / (60 * 60)) % 24);
				let hour = h < 10 ? "0" + h : h;
				this.h3 = String(hour).split("")[0];
				this.h4 = String(hour).split("")[1];
				let m = parseInt((this.secondsConstellation / 60) % 60);
				let minutes = m < 10 ? "0" + m : m;
				this.m3 = String(minutes).split("")[0];
				this.m4 = String(minutes).split("")[1];
				let s = parseInt(this.secondsConstellation % 60);
				let second = s < 10 ? "0" + s : s;
				this.s3 = String(second).split("")[0];
				this.s4 = String(second).split("")[1];
			},
			countDown2() {
				let d = parseInt(this.secondsConstellationV2 / (24 * 60 * 60));
				let day = d < 10 ? "0" + d : d;
				this.d5 = String(day).split("")[0];
				this.d6 = String(day).split("")[1];
				//let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
				let h = parseInt((this.secondsConstellationV2 / (60 * 60)) % 24);
				let hour = h < 10 ? "0" + h : h;
				this.h5 = String(hour).split("")[0];
				this.h6 = String(hour).split("")[1];
				let m = parseInt((this.secondsConstellationV2 / 60) % 60);
				let minutes = m < 10 ? "0" + m : m;
				this.m5 = String(minutes).split("")[0];
				this.m6 = String(minutes).split("")[1];
				let s = parseInt(this.secondsConstellationV2 % 60);
				let second = s < 10 ? "0" + s : s;
				this.s5 = String(second).split("")[0];
				this.s6 = String(second).split("")[1];
			},
			
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					if (this.secondsPhotography > 0) {
						this.secondsPhotography -= 1;
						this.countDown();
					} else {
						this.getTime();
					}
					if (this.secondsConstellation > 0) {
						this.secondsConstellation -= 1;
						this.countDown1();
					} else {
						this.getTime();
					}
					if (this.secondsConstellationV2 > 0) {
						this.secondsConstellationV2 -= 1;
						this.countDown2();
					} else {
						this.getTime();
					}

				}, 1000);
			},
			openweddingHtml() {
				console.log("openweddingHtml");
				this.$router.push({
					name: "Wedding"
				});
			},
			openPhotographyHtml() {
				console.log("openPhotographyHtml");
				this.$router.push({
					name: "Photography"
				});
			},
			openAtomHtml() {
				this.$router.push({
					name: "ATOMRewards"
				});
				console.log("openAtomHtml");
			},
			openCollectionHtml() {
				console.log("openCollectionHtml");
				this.$router.push({
					name: "CollectionHtml"
				});

			},
			openCollectionV2Html(){
				this.$router.push({
					name: "UptickZodiac"
				});
			},
			openOfficial(){
				this.$router.push({
					name: "OfficialAirdrop"
				});
			},
			openCreator(){
			this.$router.push({
					name: "CreatorAirdrop"
				});
			},
			openThanksgiving(){
				this.$router.push({
					name: "Thanksgiving"
				});
	
			},
			openHalloweenHtml(){
				this.$router.push({
					name: "Halloween"
				});
			},
			openPromoHtml() {
				console.log("openPromoHtml");
				this.$router.push({
					name: "Constellation"
				});
			},
			async showNoRead() {
				const json = localStorage.getItem("key_user");
				if (json) {
					let res2 = await api.home.isNewMessage({
						userAddress: this.$store.state.did,
						pushTypes:getSettingArr()
					});
					let newMsgCount = res2.data;


					// 有新消息进行弹窗
					if (newMsgCount > 0) {
						let params = {
							pageSize: 10,
							pageNumber: 1,
							owner: this.$store.state.did,
							isRead: false,
							pushTypes:getSettingArr()
						};
						let res = await api.home.sysMessage(params)
						console.log("wxl --- sysMessage", res)
						if (res.data) {
							let list = res.data.list
							for (let index = 0; index < list.length; index++) {
								setTimeout(() => {
									this.$mtip({
										title: list[index].pushContract,
									});
								}, 1000);

							}
						}
					}
				}
				window.eventBus.$emit("ViewMessage", true);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.contain {
		.content {
			//  margin-top: 45px;

			.right {
				width: 590px;
				height: 250px;
				//      background-image: linear-gradient(
				// 	#ffffff,
				// 	#ffffff),
				// linear-gradient(
				// 	#6f58d9,
				// 	#6f58d9);
				background-blend-mode: normal, normal;
				box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
				border-radius: 5px;
				cursor: pointer;

				.PhotographyImage {
					margin-left: 25px;

					img {
						width: 157px;
						height: 209px;
						display: block;
						object-fit: contain;
					}

					.timer {
						// max-width:305px;
						// margin:17px auto 21px;
						justify-content: flex-start;
						margin-top: 17px;

						.mao {
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 18px;
							font-weight: bold;
							font-stretch: normal;
							line-height: 30px;
							letter-spacing: 0px;
							color: #270645;
						}

						.bom {
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 11px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 20px;
							letter-spacing: 0px;
							color: #766983;
							text-align: center;
							margin-top: 7px;
						}

						span {
							width: 24px;
							height: 33px;
							display: inline-block;
							text-align: center;
							line-height: 33px;
							background-color: #270645;
							border-radius: 5px;
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 16px;
							font-weight: bold;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #ffffff;
						}
					}

					.title {
						//width: 326px;
						height: 62px;
						font-family: "\5FAE\8F6F\96C5\9ED1";
						font-size: 25px !important;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}

					.titleAtom {
						// width: 345px;
						height: 80px;
						font-size: 25px !important;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}

					.time {
						//width: 232px;
						height: 11px;
						font-family: "\5FAE\8F6F\96C5\9ED1";
						font-size: 13px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 13px;
						letter-spacing: 0px;
						color: #270645;
					}
				}
			}
		}

		// .content:nth-child(2) {
		//   margin-bottom: 303px;
		// }

		&.mobile {
			.content {
				// margin-top: 30px;

				.right {
					max-width: 335px;
					max-height: 140px;
					padding: 12px 14px;

					.PhotographyImage {
						margin-left: 0;

						img {
							width: 88px;
							height: 117px;
						}

						.timer {
							.bom {
								margin-top: 3px;
							}

							span {
								width: 14px;
								height: 19px;
								font-family: "\5FAE\8F6F\96C5\9ED1";
								font-size: 9px;
								text-align: center;
								font-weight: bold;
								font-stretch: normal;
								display: inline-block;
								line-height: 19px;
								letter-spacing: 0px;
								color: #ffffff;
							}
						}

						.title {
							//width:154px;
							height: 36px;
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 15px !important;
							font-weight: bold;
							font-stretch: normal;
							line-height: 16px !important;
							letter-spacing: 0px;
							color: #270645;
							margin-bottom: 11px;
						}

						.time {
							//width:158px;
							height: 10px;
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 11px !important;
							font-weight: normal;
							font-stretch: normal;
							line-height: 12px !important;
							letter-spacing: 0px;
							color: #270645;
						}
					}
				}
			}

			// .content:nth-child(2) {
			//   margin-bottom: 100px;
			// }
		}
	}
</style>
